.tabs {
  .desktop-only {
    display: none;
    @media(min-width:960px) {
      display: block;
    }
    .tab-btn-select {
      border-bottom:1px #DADADA solid;
      span {
        display:inline-block;
        font-size:16px;
        font-weight:600;
        padding:10px 20px;
        color:#A9A9A9;
        border-right:1px #DADADA solid;
        border-top:1px #DADADA solid;
        position: relative;
        top:1px;
        cursor: pointer;
        &.selected {
          color:#4A4A4A;
          background-color:#F6F9FA;
          border-bottom:1px #F6F9FA solid;
        }
        &:hover {
          color:#4A4A4A;
        }
        &:nth-child(1) {
          border-left:1px #DADADA solid;
        }
      }
    }
    .tab-content {
      background:#F6F9FA;
      padding:50px 0;
      h3 {
        font-weight:700;
        font-size: 29px;
        color: #0C314E;
        letter-spacing: 0;
        margin:0 0 25px;
      }
    }
  }

  .mobile-only {
    display:none;
    @media(max-width:960px) {
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      margin:0 0 75px;
      li {
       margin:10px 0;
       border: 1px solid #E3E3E3;
       a.toggle {
          width: 100%;
          display: block;
          background:#FBFBFB;
          height: 80px;
          line-height: 80px;
          transition: background .3s ease;
          padding:0 20px;
          font-size:16px;
          font-weight:600;
          color:#A9A9A9;
          &.active {
            color:#4A4A4A;
          }
        }
        .inner {
          overflow: hidden;
          display: none;
          padding:20px;
          overflow-x: auto;
          ul {
            border:0;
            li {
              margin:0;
              border:0;
            }
          }
        }
      }
    }
  }
}
