.clients-who-we-work-with {
  //padding:90px 0;
  padding-bottom:90px;
  @media(max-width:900px) { /*padding:70px 0;*/ padding-bottom:70px; }
  @media(max-width:600px) { /*padding:60px 0;*/ padding-bottom:60px; }
  @media(max-width:450px) { /*padding:50px 0;*/ padding-bottom:50px; }
  h2 {
    font-size: 29px;
    color: #0C314E;
    letter-spacing: 0;
    line-height: 1.79310344828;
    margin:0;
    @media(max-width:900px) { font-size:25px; }
    @media(max-width:600px) { font-size:22px; }
    @media(max-width:450px) { font-size:20px; }
  }
  ul {
    display:flex;
    flex-wrap:wrap;
    li {
      width:calc(25% - 15px);
      margin-left:20px;
      background:#fff;
      height:200px;
      border:1px #E0E0E0 solid;
      margin-top:20px;
      @media(min-width:960px) {
        &:nth-child(4n+1) {
          margin-left:0;
        }
      }
      @media(min-width:740px) AND (max-width:960px) {
        width:calc(33% - 14px);
        &:nth-child(3n+1) {
          margin-left:0;
        }
      }
      @media(min-width:460px) AND (max-width:740px) {
        width:calc(50% - 10px);
        &:nth-child(2n+1) {
          margin-left:0;
        }
      }
      @media(max-width:460px) {
        width:calc(100%);
        margin-left:0;
      }

    }
  }
}

.v-center {
  display:flex;
  justify-content:center;
  align-items:center;
  img{
    width: 60%;
  }
}

.case-studies-selection {
  padding:90px 0;
  @media(max-width:900px) { padding:70px 0; }
  @media(max-width:600px) { padding:60px 0; }
  @media(max-width:450px) { padding:50px 0; }
}

.arrow-wrap {
  a {
    background:#7CABBD;
    color:#fff;
    min-width: 90px;
    height: 42px;
    line-height: 40px;
    display: inline-block;
    text-align:center;
    font-weight:700;
    font-size: 13px;
    letter-spacing: 0.81px;
    &:nth-child(1) {
      margin-right:10px;
      &:before {
        font-family: FontAwesome;
        content: "\f0d9";
        margin-right:15px;
        font-size:15px;
      }
    }
    &:nth-child(2) {
      &:after {
        font-family: FontAwesome;
        content: "\f0da";
        margin-left:15px;
        font-size:15px;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.item-spec {
  background: #0C314E;
  padding: 25px;
  text-align: right;
  margin-bottom:40px;
  h3 {
    padding:5px 0;
    display:flex;
    justify-content: space-between;
    align-content: space-between;
    border-top: 1px #8D8D8D solid;
    font-size:16px;
    strong {
      color:#fff;
      font-weight:700;
      padding-right:35px;
      font-weight:700;
    }
    span {
      color:#fff;
      font-weight:400;
    }
    &:nth-child(1) {
      border-top:0;
    }
  }
}
