.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}

.fullpage{
  padding: 110px 0 80px 0;
}


.general-template {
  padding-top:125px;
  padding-bottom:60px;
  border-bottom:1px #E0E8EA solid;
  @media(max-width:1100px) { padding-top:105px; }
  @media(max-width:900px) { padding-top:90px; }
  @media(max-width:600px) { padding-top:75px; }
  @media(max-width:450px) { padding-top:50px; }
  .row {
    width:100%;
    margin-left:0;
    margin-right:0;
    .inner-wrap {
      display:flex;
      .col {
        &:nth-child(1) {
          width:calc(100% - 380px);
          padding-right:80px;
          @media(max-width:1000px) {
            width:calc(100%);
            padding-right:0;
          }
        }
        &:nth-child(2) {
          width:380px;
          @media(max-width:1000px) {
            display: none;
          }
        }
        .general {
          p {
            margin:0 0 1.5em;
          }
          ul{
            list-style: disc;
            margin: 0 0 20px 30px;
          }
        }
      }
    }
  }

  .submenu-box {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.10);
    margin-bottom:35px;
    .inner {
      border-top:6px solid #75A2B8;
      padding:30px 25px;
      h3 {
        font-size: 24px;
        color: #414141;
        letter-spacing: 0;
        font-weight:700;
        margin:5px 0;
        @media(max-width:900px) { font-size:21px; }
        @media(max-width:600px) { font-size:19px; }
        @media(max-width:450px) { font-size:17px; }
        a {
          color:#414141;
        }
      }
      a.img-wrap {
        height: 180px;
        overflow: hidden;
        margin:15px 0;
        display: block;
        img {
          min-height:180px;
        }
      }
      p {
        font-size: 15px;
        color: #7E7E7E;
        letter-spacing: 0;
        line-height: 1.66;
        margin:0 0 15px;
      }
    }
  }
}

.socialmedia{
  display: inline-block;
  margin:0;
      margin-top: 10px;
  a{
    border-radius: 50%;
    display: inline-block;
    width: 33px;
    height: 33px;
    padding-top: 8px;
    justify-content: center;
    margin-left: 8px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    &.fa-facebook{background: #44659E;}
    &.fa-linkedin{background: #0282C0;}
    &.fa-youtube{background: #DE381B;}
    &:nth-child(1) {
      margin-left: 0;
    }
    &:hover{
      text-decoration: none;
    }
  }
}


.allvideos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  @media(max-width:760px) {
    flex-direction: column;
  }
  .video{
    width: calc(50% - 20px);
    margin-bottom: 40px;
    height: 0;
    padding-top: 27%;
    position: relative;
    @media(max-width:760px) {
      width: 100%;
      padding-top: 56%;
    }
    iframe{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left:0;
    }
  }
}
