.homepage {
  header {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 500;
    background-color:transparent;
  }

  ul.product-selection {
    li {
      .btn-wrap {
        background:#F6F9FA;
      }
    }
  }

  .content-wrap {
    margin:0 auto 50px;
    .inner-wrap {
      display: flex;
      position: relative;
      background:$blue;
      overflow: hidden;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        position: relative;
        z-index: 100;
        color:#fff;
        @media(max-width:1200px) {
          width:50%;
        }
        @media(max-width:900px) {
          width:100%
        }
        h1 {
          color:#fff;
          font-size:29px;
          font-weight:700;
          @media(max-width:600px) { font-size:27px; }
          @media(max-width:450px) { font-size:24px; }
        }
        p {
          margin: 0 0 1.5em;
          font-size:15px;
          font-weight:400;
          color:#A3A9C2;
          line-height: 1.4;
          &:nth-child(1) {
            font-size:18px;
            color:#fff;
            font-weight:700;
          }
        }
        img {
          height:100%;
          max-width: none;
          @media(max-width:900px) {
            width:100%;
            height:auto;
          }
        }
        &:nth-child(1) {
          padding:60px;
          background:$blue;
          @media(max-width:600px) { padding:45px; }
          @media(max-width:450px) { padding:30px; }

        }
        &:nth-child(2) {
          padding:30px 0;
          @media(max-width:1200px) { padding:0; }
        }
      }
      &:before {
        content: " ";
        position: absolute;
        height:100%;
        right:0;
        top:0;
        width:200px;
        background:#fff;
        @media(max-width:1200px) { display:none; }
      }
      &:after {
        content: " ";
        position: absolute;
        height:100%;
        right:200px;
        top:0;
        width:200px;
        background:#fff;

        border-style: solid;
        border-width: 425px 270px 0 0;
        border-color: $blue transparent transparent transparent;
        @media(max-width:1200px) { display:none; }

      }
    }
  }
}


.products-overview {
  padding:60px 0;
  h2 {
    margin:10px 0;
    font-size: 29px;
    color: #0C314E;
    letter-spacing: 0;
    line-height: 1.3;
    @media(max-width:900px) { font-size:27px; }
    @media(max-width:600px) { font-size:23px; }
    @media(max-width:450px) { font-size:20px; }
  }
}

.who-we-work-with-wrap {
  padding:100px 0;
  background:#F6F9FA;
  border-top:1px #E1E8EA solid;
  border-bottom:1px #E1E8EA solid;
  @media(max-width:900px) { padding:40px 0; }
  .inner-wrap {
    .col {
      display: inline-block;
      vertical-align: top;
      &:nth-child(1) {
        width:512px;
        padding-right:85px;
        @media(max-width:1200px) {
          width:45%;
          padding-right: 5%;
        }
        @media(max-width:900px) {
          width:100%;
          padding-bottom:55px;
          text-align:left;
          padding-right:0;
        }
      }
      &:nth-child(2) {
        width:calc(100% - 512px);
        @media(max-width:1200px) {
          width:50%;
          float: right;
        }
        @media(max-width:900px) {
          width:100%;
        }
      }
      h2 {
        font-size: 29px;
        color: #0C314E;
        letter-spacing: 0;
        line-height: 1.3;
        font-weight:700;
      }
      p {
        font-size:15px;
        line-height: 1.5;
        color:#0C314E;
        margin:0 0 1.5em;
        &.first {
          font-size:18px;
          line-height: 1.3;
          font-weight:700;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        border-top:1px #E0E0E0 solid;
        border-left:1px #E0E0E0 solid;
        max-width:673px;
        margin:0 auto;
        @media(max-width:900px) {
          max-width:100%;
        }
        li {
          background:#fff;
          width:calc(33.3333333%);
          border-bottom:1px #E0E0E0 solid;
          border-right:1px #E0E0E0 solid;
          height:150px;
          text-align:center;
          display:flex;
          justify-content:center;
          align-items:center;
          padding: 10px;
          @media(max-width:700px) {
            width:50%;
          }
          @media(max-width:300px) {
            width:100%;
          }
          img{
            width: 70%;
          }
        }

      }
    }
  }
}

.find-out-more-btns {
  padding:90px 0;
  @media(max-width:900px) { padding:40px 0; }
  .inner-wrap {
    ul {
      margin:0;
      padding:0;
      display: block;
      @media(max-width:600px) {
        max-width:100%;
        margin:0 auto;
      }
      li {
        display:inline-block;
        height:0;
        padding-top: 20%;
        width:calc(33.33333% - 14px);
        margin:0;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        overflow: hidden;
        @media(max-width:600px) {
          width:100%;
          height: 0;
          padding-top: 45%;
          display: block;
        }
        &:nth-child(3n+2) {
          margin-left:20px;
          margin-right:20px;
          @media(max-width:600px) {
            margin-left:0;
            margin-right:0;
            margin-top:20px;
            margin-bottom:20px;
          }
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          padding-top: 0;
          bottom: 0;
          position: absolute !important;
          &:active{

          }
          .btn {
            @media(max-width:900px) {
              display: none;
            }
          }
        }
        .inner {
          position: absolute;
          padding:20px;
          bottom:0;
          left:0;
          width:100%;

          display: flex;
          align-items: flex-end;
          align-content: flex-end;
          flex-wrap:wrap;
          background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.70) 100%);
          @media(max-width:900px) {
              padding: 3px 10px;
          }
          &:active{

          }
          h3 {
            font-size: 25px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 1;
            margin:10px 0;
            @media(max-width:1050px) {
              font-size:22px;
            }
            @media(max-width:900px) {
                font-size:17px;
            }
          }
        }
      }
    }

  }
}
