
h1, h2, h3 {
  font-family:$title-font-family;
  font-weight:700;
  color:#0C314E;
}

h1 {
  font-size:42px;
  line-height:1.1;
  margin:0 0 30px;
  @media (max-width:900px) { font-size:36px; }
  @media (max-width:600px) { font-size:32px; }
  @media (max-width:450px) { font-size:30px; }
}


h2 {
  margin:10px 0;
  font-size: 29px;
  color: #0C314E;
  letter-spacing: 0;
  line-height: 1.79310344828;
  @media (max-width:900px) { font-size:26px; }
  @media (max-width:600px) { font-size:23px; }
  @media (max-width:450px) { font-size:21px; }
}
