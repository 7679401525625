
$title-font-family:	"proxima-nova";
$standard-font-family: "proxima-nova";

$container: 1190;

$blue: #0C304E;
$alt-blue: #F6F9FA;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
