/* selection */
.product-header {
  padding:105px 0;
  @media(max-width:900px) { padding:90px 0; }
  @media(max-width:600px) { padding:70px 0; }
  @media(max-width:450px) { padding:55px 0; }
  .inner-wrap {
    padding-top:15px;
    display: flex;
    justify-content: space-between;
    @media(max-width:900px) { display: block; }
    .col {
      width:calc(50% - 40px);
      @media(max-width:900px) { width:100%; }
      h1 {
        font-size: 50px;
        color: #0C314E;
        font-weight:700;
        @media(max-width:1300px) { font-size:46px; }
        @media(max-width:1200px) { font-size:43px; }
        @media(max-width:1100px) { font-size:40px; }
        @media(max-width:1000px) { font-size:36px; }
        @media(max-width:900px) { font-size:33px; }
      }
      .txt-wrap {
        p {
          color:#0C314E;
          font-size:15px;
          margin:0 0 1.5em;
          &:nth-child(1) {
            font-size:18px;
            font-weight:700;
            margin:0 0 1.5em;
          }
        }
      }
      &:nth-child(2) {
        @media(max-width:900px) {
          display: none;
        }
        @media(max-width:700px) {
          display: block;
          margin: 30px 0 0 0;
        }
      }
    }
  }
}

.product-selection-wrap {
  padding:90px 0;
  background: #F6F9FA;
  border-top: 1px solid #E1E8EA;
  border-bottom: 1px solid #E1E8EA;
  h2 {
    font-size: 29px;
    color: #0C314E;
    letter-spacing: 0;
    line-height: 1;
    font-weight:700;
  }
}


ul.product-selection {
  margin:0;
  display: block;
  li {
    display: inline-block;
    width:calc(25% - 15px);
    margin:10px 0 10px 20px;
    border:1px #E0E0E0 solid;
    @media(max-width:1150px) {
      width:calc(33.33% - 22.5px);
    }
    @media(min-width:1150px) {
      &:nth-child(4n+1) {
        margin-left:0;
      }
    }
    @media(min-width:900px) and (max-width:1150px) {
      width:calc(33% - 11.5px);
      &:nth-child(3n+1) {
        margin-left:0;
      }
    }
    @media(min-width:600px) and (max-width:900px) {
      width:calc(50% - 10px);
      &:nth-child(2n+1) {
        margin-left:0;
      }
    }
    @media(max-width:600px) {
      width:100%;
      margin-left:0;
    }
    .img-wrap {
      height:0;
      padding-top: 55%;
      border:10px #fff solid;
      overflow: hidden;
      width:100%;
      background-size:cover;
      background-repeat:no-repeat;
      background-position: center;
      display: block;
      img {
        display: none;
      }

    }
    .btn-wrap {
      /* Rectangle 7: */
      background: #E0E6E8;
      border-top: 1px solid #E0E0E0;
      font-size: 16px;
      font-weight:700;
      padding:10px;
      position: relative;
      a {
        color: #476479;
      }
      &:after {
        font-family: FontAwesome;
        content: "\f054";
        position: absolute;
        top:0;
        right:0;
        color: #476479;
        padding:10px;
        font-size: 18px;
      }

    }
  }
}


/* product details page*/
.product-view-overview {
  padding:75px 0;
  .inner-wrap {
    display: flex;
    @media(max-width:900px) {
      display: block;

    }
  }
  .gallery-items {
    width:50%;
    position: relative;
    vertical-align: top;
    order: 1;
    img {
      max-height: 100%;
    }
    @media(max-width:900px) {
      width: 100%;
      display: block;
    }
    .gallery-items-container {
      position: relative;
    }
    .gallery-wrapper {
      //height:415px;
      background: #FFFFFF;
      box-shadow: 0 0 3px 0 rgba(0,0,0,0.24);
      width:100%;
      height: 0;
      padding-top: 76%;
      display: block;
      text-align:center;
      margin-bottom:15px;
      @media(max-width:900px) {
        padding-top: 65%;
      }
      .cycle-carousel-wrap{
        height: 100%;
        span{
          height: 100%;
        }
      }
      .img-wrap {
        display:none;
        width:100%;
        border:10px solid #fff;

        //height:415px;
        overflow: hidden;
        &:nth-child(1) {
          display:block;
        }
      }
    }
    .thumbs-wrapper {
      position: relative;
      height:130px;
      border-right:1px solid #eee;
      @media(max-width:540px) {
        height:100px;
      }
      @media(max-width:410px) {
        height:90px;
      }
      .thumb-wrap {
        display: inline-block;
        padding:2px;
        height:130px;
        padding: 10px;
        border:1px solid #eee;
        overflow: hidden;
        cursor: pointer;
        margin-left:0px;
        margin-right:10px;
        @media(max-width:540px) {
          height:100px;
        }
        @media(max-width:410px) {
          height:90px;
        }
        .thumb-outter {
          display: block;
          height:100%;
          background: #FFFFFF;

          text-align:center;
          overflow: hidden;
          img {
            width: 100%;
            max-height: fit-content;
          }
        }
      }
      .cycle-prev,
      .cycle-next {
        display:inline-block;
        width:40px;
        line-height: 40px;
        height:40px;
        background:$blue;
        z-index: 1000;
        position: absolute;
        top:45px;
        text-align:center;
        border-radius: 50%;
        font-size:19px;
        cursor: pointer;
        i {
          color:white;
        }
      }
      .cycle-prev { left:10px; }
      .cycle-next { right:10px; }
    }
  }
  .txt-wrap {
    width:50%;
    vertical-align: top;
    order: 2;
    padding:10px 0 10px 70px;
    @media(max-width:900px) {
      width: 100%;
      display: block;
      padding:0 0 35px;
      margin-top: 30px;
    }
    h1 {
      font-size: 32px;
      @media(max-width:900px) { font-size:30px; }
      @media(max-width:600px) { font-size:27px; }
      @media(max-width:450px) { font-size:24px; }
      color: #32323A;
      font-weight:700;
    }
    p {
      margin:0 0 1.5em;
      &:nth-child(1) {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 1.5em;
      }
    }
    .tel {
      font-size: 28px;
      color: #7BACBF;
      font-weight: 700;
      margin: 45px 0;
      display:block;
      @media(max-width:900px) { font-size:24px; }
      @media(max-width:600px) { font-size:20px; }
      @media(max-width:450px) { font-size:18px; }
      &:before {
        content: "\f095";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        font-size: 34px;
        @media(max-width:900px) { font-size:18px; }
        @media(max-width:600px) { font-size:14px; }
        @media(max-width:450px) { font-size:12px; }
        display: inline-block;
        padding-right: 10px;
        position: relative;
        top: 5px;
      }
    }
    span.request-btn {
      background-color:$blue;
      font-size:16px;
      padding:15px 25px;
    }
  }
}


ul.listDocuments {
  @extend .product-selection;
  li {
    a.img-wrap {
      background:#C43A3E;
      text-align:center;
      align-items:center;
      justify-content:center;
      display: flex;
      img {
        display: inline-block;
      }
    }
    a.txt-wrap { }
  }
}
