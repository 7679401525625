header {
  padding-top:30px;
  background:#7BACBF;
  .page-header-inner {
    display:flex;
    justify-content:space-between;
    align-content:space-between;
    height:111px;
    @media(max-width:900px) { height:93px; }
    @media(max-width:640px) { height:82px; }
    #logo{
      @media(max-width:900px) { width: 180px; }
      @media(max-width:640px) { width: 160px; }
    }
    ul {
      position: relative;
      display: block;
      @media(max-width:1200px) {
        position: absolute;
        right: 117px;
      }
      @media(max-width:640px) {display: none;}
      li {
        display: inline-block;
        overflow: hidden;
        background:#fff;
        a {
          font-size: 14px;
          color: #0C304E;
          letter-spacing: 2px;
          text-transform: uppercase;
          padding:0 15px;
          display: inline-block;
          height:61px;
          line-height: 61px;
          background:#fff;
        }
        &:nth-child(1) {
          a { padding-left:30px; }
        }
        &:nth-child(5) {
          a { padding-right:30px; }
        }
        &.active {
          a { font-weight:700; }
        }
        &.parent{
          @media(max-width:1200px) {
            display: none;
          }
        }
        &.tel {
          a {
            background:$blue;
            color:#fff;
            padding:0 30px;
            font-family: "proxima-nova",sans-serif;
            font-style: normal;
            font-weight: 700;
            &:before {
              font-family: FontAwesome;
               content: "\f095";
               display: inline-block;
               padding-right: 15px;
               vertical-align: middle;
               font-size:20px;
            }
          }
        }
      }
      &:before {
        position: absolute;
        content: " ";
        left:-36px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 61px 36px;
        border-color: transparent transparent #ffffff transparent;
        @media(max-width:1200px) {
          border-color: transparent transparent $blue transparent;
        }
      }
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  background:#fff;
  cursor: pointer;
  display:none;
  height: 61px;
  margin:0;
  float: right;
  text-align:center;
  transition: right 0.5s;
  width: 61px;
  z-index: 100;
  color:$blue;
  @media(max-width:640px) {
    height: 50px;
    width: 50px;
  }
  i {
    font-size:28px;
    position: relative;
    top:17px;
    &:nth-child(2) {
      display:none;
    }
    @media(max-width:640px) {
      top: 13px;
    }
  }
  @media(max-width:1200px) {
    display: block;
    z-index: 9999;
    float: right;
    position: absolute;
    right: 56px;
  }
  @media(max-width:640px) {
    right: 20px;
  }
  &.menu-open {

    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
