.contact-page-wrap {
  padding-bottom:65px;
  h1 {
    margin:0 0 40px 0;
  }
  .map {
    margin-bottom:80px;
  }
  .row {
    display: block;
    margin-left:0;
    margin-right:0;
    .col {
      display:inline-block;
      vertical-align: top;
      width:50%;
      @media(max-width:900px) {
        width:100%;
      }
      &:nth-child(2) {
        width:calc(50% - 100px);
        margin-left:100px;
        border-left:5px #32323A solid;
        padding-left:45px;
        @media(max-width:900px) {
          margin-left:0;
          margin-top:55px;
          width:100%;
        }
      }
      h2 {
        color:#32323A;
        font-size:23px;
        margin:0 0 20px;
      }
      form {
        label {

        }
      }
      .location {
        margin: 0 0 30px 0;
        strong {
          font-size: 18px;
          color: #76797B;
          letter-spacing: 0;
          line-height: 1.5em;
          display: block;
          margin-bottom: 5px;
        }
        p {
          font-weight:400;
          font-size: 14px;
          color: #787B7D;
          letter-spacing: 0;
          line-height: 1.3em;
          margin:0 0 1.5em;
        }
        .telephone {
          &:before {
            width:30px;
            font-family:FontAwesome;
            content: "\f095";
            display: inline-block;
            padding-right: 5px;
            vertical-align: middle;
            text-align:center;
          }
          font-size:18px;
          font-weight:700;
          color: $blue;
        }
        .email {
          @extend .telephone;
          &:before {
            content: "\f0e0";
            color:#7BACBF;
          }
          color:#7BACBF;
        }
      }
    }
  }
}
