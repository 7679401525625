.dialog-item {
  max-width: 600px;
  width: 80%;
  .dialog-item-inner {
    padding: 35px;
    h1 {
      color:#32323A;
      font-size:23px;
    }
  }
}
