.about-header {
  .row {
    margin-left:0;
    margin-right:0;
    padding-top:115px;
    padding-bottom:75px;
    display: flex;
    @media(max-width:900px) { padding-top:90px; }
    @media(max-width:600px) { padding-top:70px; }
    @media(max-width:450px) { padding-top:55px; }
    .col {
      width:50%;
      &:nth-child(2) {
        padding-left:50px;
        @media(max-width:900px) {
          padding-left:0;
          img{display: none;}
        }
        @media(max-width:600px) {
          img{display: block;}
        }
      }
      p {
        margin:0 0 1.5em;
      }
    }
    @media(max-width:900px) {
      display: block;
      .col {
        width:100%;
        &:nth-child(2) {
          margin-top:35px;
          padding-left:0;
          text-align:center;
        }
      }
    }
  }
}

.additional-area {
  background: #F6F9FA;
  border-top: 1px solid #E1E8EA;
  border-bottom: 1px solid #E1E8EA;
  padding-top:70px;
  padding-bottom:70px;
  @media(max-width:900px) { padding-top:60px; padding-bottom:60px;  }
  @media(max-width:600px) { padding-top:50px; padding-bottom:50px;  }
  @media(max-width:450px) { padding-top:40px; padding-bottom:40px;  }
  .row {
    margin:0;
    display: flex;
    @media(max-width:760px) {
      display: block;
    }
    .col {
      display:flex;
      align-items:center;
      &:nth-child(1) {
        background-color:#fff;
        width:290px;
        height:200px;
        border:1px #E0E0E0 solid;
        text-align:center;
        justify-content:center;
        @media(max-width:760px) {
            width:100%;
        }
        img {
          display: block;
        }
        @media(max-width:760px) {
            margin:0 auto 35px;
        }
      }
      &:nth-child(2) {
        flex:1;
        padding:0 35px;
        h2 {
          margin:0 0 10px;
          line-height: 1;
        }
        @media(max-width:760px) {
          display: block;
          text-align:left;
          padding:0;
        }
      }
    }
  }
}

.gallery-wrap {
  padding:80px 0;
  @media(max-width:900px) { padding:70px 0; }
  @media(max-width:600px) { padding:55px 0; }
  @media(max-width:450px) { padding:40px 0; }
  .row {
    margin:0 0 15px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    width:100%;
    &:nth-child(1) {
      margin-left:-15px;
      margin-right:-15px;
      width:calc(100% + 30px);
      @media(max-width:900px) {
        margin-left:0;
        margin-right:0;
        width:calc(100%);
      }
      @media(min-width:1250px) {
        width:100%;
        margin-left:0;
        margin-right:0;
      }
    }
    @media(max-width:600px) {
      display: block;
    }
    .col {
      margin-left:10px;
      &:nth-child(1) {
        margin-left:0;
      }
      .item-wrap {
        background-color:#75A2B8;
        background-size:cover;
        background-repeat:no-repeat;
        background-position: center;
        img {
          opacity:0;
        }
      }
      @media(max-width:600px) {
        margin-left:0;
        margin-top:20px;
      }
    }
    &:nth-child(1) {
      @media(max-width:900px) {
        width:calc(100%);
        display: block;
      }
      .col {
        width:calc(50% - 20px);
        margin-left:0;
        @media(max-width:900px) {
          width:calc(100%);
        }
        .item-wrap {
          height:390px;
          display:flex;
          justify-content:center;
          align-items:center;
          color:#fff;
          overflow: hidden;
          width:100%;
          &.txt-wrap {
            flex-direction: column;
            margin-bottom:15px;
            padding-left:50px;
            padding-right:50px;
            font-weight:300;
            font-size: 15px;
            color:rgba(#FFF, 0.74);
            letter-spacing: 0;
            line-height: 1.53333333333;
            @media(max-width:900px) {
              display: block;
              padding-top:50px;
              padding-bottom:50px;
              height:auto;
            }
            @media(max-width:600px) {
              padding:40px;
            }
            @media(max-width:450px) {
              padding:30px;
            }
            h4 {
              display: block;
              width:100%;
              text-align:left;
              font-size: 29px;
              color: #FFFFFF;
              letter-spacing: 0;
              line-height:1.39310344828;
            }

          }
        }
        &:nth-child(2) {
          .item-wrap {
            height:100%;
            @media(max-width:900px) {
              margin-top:15px;
            }
          }
        }
      }
    }
  }
}
