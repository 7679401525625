.call-to-action-wrap {
  background:#F6F9FA;
  border-top:1px solid #E1E8EA;
  padding:95px 0;
  text-align:center;
  @media(max-width:900px) { padding:80px 0;  }
  @media(max-width:600px) { padding:65px 0; }
  @media(max-width:450px) { padding:50px 0;  }
  .inner-wrap {
    max-width:878px;
    margin:0 auto;
  }
  h3 {
    font-size: 29px;
    color: #0C314E;
    letter-spacing: 0;
    line-height: 1.8;
    @media(max-width:900px) { font-size:26px; }
    @media(max-width:600px) { font-size:22px; }
    @media(max-width:450px) { font-size:19px; }
  }
  p {
    opacity: 0.74;
    font-size: 15px;
    color: #0C314E;
    letter-spacing: 0;
    line-height: 1.5;
    margin:20px 0 30px;
    @media(max-width:900px) { font-size:14px; }
    @media(max-width:600px) { font-size:13px; }
  }
  .btn-wrap {
    .tel {
      display: inline-block;
      font-size:20px;
      font-style: normal;
      font-weight: 300;
      color:#0C314E;
      padding-right:30px;
      span {
        display: inline-block;
        padding-right:5px;
      }
      a {
        font-weight:700;
        color:#0C314E;
      }
      @media(max-width:600px) { 
        display: block;
        padding-right:0;
        margin-bottom:10px;
      }
    }
    a.btn {
      display: inline-block;
      background:#7CABBD;
    }
  }
}

footer {
  padding:45px 0;
  background:$blue;
  .inner-wrap {
    display:flex;
    justify-content: space-between;
    color:#fff;
    @media(max-width:900px) {
      flex-direction: column;
      text-align:center;
    }
    .col {
      ul {
        li {
          display: block;
          font-size: 13px;
          color: #92A2AF;
          line-height: 1.4;
          font-weight:300;
          a {
            color: #92A2AF;
          }
          &:nth-child(1) {
            margin-bottom:5px;
            font-size: 14px;
            color:#fff;
            line-height: 1.5;
            a {
              color:#fff;
            }
          }
        }
      }
      &:nth-child(1) {
        @media(max-width:900px) { margin-bottom:35px; }
      }
    }
  }
}
