.home-slideshow {
  position: relative;
  z-index: 400;
  max-height:850px;
  height:100vh;
  overflow: hidden;
  @media(max-width:960px) {
    height:90vh;
  }
  @media(max-width:760px) {
    height:80vh;
  }
  @media(max-width:760px) and (orientation : landscape) {
    height:130vh;
  }

  .slide {
    position: relative;
    max-height:850px;
    height:100vh;
    width:100%;
    .home-slideshow-img {
      max-height:850px;
      height:100vh;
      width:100%;
      display: block;
      background-repeat:no-repeat;
      background-size:cover;
      background-position: center;
      position: absolute;
      top:0;
      left:0;
      z-index: 100;
      @media(max-width:760px) {
        height:80vh;
      }
      @media(max-width:760px) and (orientation : landscape) {
        height:130vh;
      }
    }
    .home-slideshow-overlay {
      width:100%;
      display: block;
      position: absolute;
      top:0;
      left:0;
      @media(max-width:760px) {
        height:80vh;
      }
      @media(max-width:760px) and (orientation : landscape) {
        height:130vh;
      }
      &:before {
        background:rgba(#7BACBF, 0.94);
        max-height:850px;
        height:100vh;
        position: absolute;
        top:0;
        left:0;
        z-index: 200;
        width:calc(50% - 200px);
        content: " ";
        @media(max-width:1250px) {
          width:calc(100% - 600px);
        }
        @media(max-width:960px) {
          width:calc(100% - 500px);
        }
        @media(max-width:860px) {
          width:calc(100% - 400px);
        }
        @media(max-width:760px) {
          width:100%;
          background:rgba(#7BACBF, 0.74);
        }
        @media(max-width:760px) and (orientation : landscape) {
          height:130vh;
        }

      }
      &:after {
        content: " ";
        left:calc(50% - 200px);
        @media(max-width:1250px) {
          left:calc(100% - 600px);
        }
        @media(max-width:960px) {
          left:calc(100% - 500px);
        }
        @media(max-width:860px) {
          left:calc(100% - 400px);
        }
        @media(max-width:760px) {
          display: none
        }

        position: absolute;
        top:0;
        z-index: 200;
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 850px;
        border-right-width:400px;
        border-left-width:0;
        border-bottom-width:0;
        border-color:rgba(#7bacbf, 0.94) transparent transparent transparent;
        @media(max-height:850px) {
          border-top-width:100vh;
        }
      }
    }
    .home-slideshow-inner {
      width:100%;
      display:flex;
      align-items:center;
      max-height: 850px;
      height:100vh;
      position: absolute;
      top:0;
      left:0;
      z-index: 300;
      @media(max-width:760px) {
        height:90vh;
      }
      @media(max-width:760px) and (orientation : landscape) {
        height:130vh;
      }
      .inner-wrap {
        .txt-wrap {
          max-width:504px;
          @media(max-width:760px) {
            max-width:100%;
          }
          @media(max-width:760px) and (orientation : landscape) {
            margin-top: 90px;
          }
          h2 {
            font-family: "adelle",serif;
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height:1;
            margin:0 0 30px;
            @media(max-width:1100px) { font-size:46px; }
            @media(max-width:1000px) { font-size:43px; }
            @media(max-width:900px) { font-size:40px; }
            @media(max-width:750px) { font-size:38px; }
            @media(max-width:600px) { font-size:35px; }
            @media(max-width:450px) { font-size:33px; }

          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 1.4;
            margin:0 0 30px;
            @media(max-width:1100px) { font-size:19px; }
            @media(max-width:1000px) { font-size:18px; }
            @media(max-width:900px) { font-size:17px; }
            @media(max-width:750px) { font-size:16px; }
            @media(max-width:600px) { font-size:15px; }
            @media(max-width:450px) { font-size:14px; }
          }
          a.btn {
            padding:10px 20px;
            font-size: 16px;
            color: #FFFFFF;
            letter-spacing: 1px;
            line-height:1.5;
            font-weight:700;
            background:$blue;
            @media(max-width:1100px) { font-size:15px; }
            @media(max-width:1000px) { font-size:14px; }
            @media(max-width:900px) { font-size:13px; }
            @media(max-width:750px) { font-size:12px; }
          }
        }
      }

    }
  }
}
