#mobile-nav-items {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15.625em;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align:left;
    background:$blue;
    ul {
      margin:10px 0;
      li {
        color:#fff;
        font-weight:700;
        text-transform: uppercase;
        display: block;
        padding:5px 20px;
        text-align:left;
        li {
          padding:5px 10px;
        }
        a,span {
          color:#fff;
          display:inline-block;
          line-height: 1.25em;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          &.active {

          }
        }
      }
      &.second {
        display: none;
      }
      &.open {
        display: block;
      }
    }
}
